<template>
  <a-drawer class="activity-add" title="问卷相关" @close="onClose" :width="720" :visible="addVisiable" :maskClosable="false">

    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="问卷标题" prop="title" v-bind="formItemLayout">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description" v-bind="formItemLayout">
        <a-textarea placeholder="描述" v-model="form.description" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="开始日期" prop="beginDate" v-bind="formItemLayout">
        <a-date-picker format="YYYY-MM-DD" v-model="form.beginDate" />
      </a-form-model-item>
      <a-form-model-item label="结束日期" prop="endDate" v-bind="formItemLayout">
        <a-date-picker format="YYYY-MM-DD" v-model="form.endDate" />
      </a-form-model-item>
      <a-form-model-item label="问卷链接" prop="link" v-bind="formItemLayout">
        <a-input v-model="form.link" />
      </a-form-model-item>
      <a-form-model-item label="调研对象" prop="rules">
        <user-rule-creator
          @valueChange="rulesValueChange"
          ref="userRuleCreator"
        />
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}
import UserRuleCreator from "../../business-common/UserRuleCreator";

export default {
  name: "InvestigationAdd",
  components: { UserRuleCreator },
  props: {
    addVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      groups: [],
      form: {
        title: undefined,
        description: undefined,
        beginDate: undefined,
        endDate: undefined,
        rules: undefined,
        link: undefined
      },
      rules: {
        title: [
          { required: true, message: '问卷标题不能为空', trigger: 'blur' }
        ],
        beginDate: [
          { required: true, message: '问卷开始日期不能为空', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '问卷结束日期不能为空', trigger: 'change' }
        ],
        rules: [{ required: true, message: "请指定规则", trigger: "blur" }],
        link: [
          { required: true, message: '问卷链接不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.reset();
      this.$emit("close");
    },
    rulesValueChange(s) {
      this.form.rules = s;
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let beginDate = this.form.beginDate?this.form.beginDate.add(1, 'days').toDate():null;
          let endDate = this.form.endDate?this.form.endDate.add(1, 'days').toDate():null;
          this.$post("questionnaire-investigation", {...this.form, beginDate, endDate}).then(r => {
            console.log("添加问卷活动相关", r.data);
            this.$emit("success");
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>