<template>
  <a-card :bordered="false" class="card-area">

    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="5">
           <a-form-item label="问卷名称">
              <a-input v-model="queryParams.title" placeholder="问卷名称" />
            </a-form-item>
          </a-col>
        </a-row>
				<a-row>
				  <a-col :span="12"></a-col>
				  <a-col :span="12" :style="{ textAlign: 'right' }">
				    <a-button type="primary" @click="search">查询</a-button>
				    <a-button style="margin-left: 8px" @click="reset">重置</a-button>
				  </a-col>
				</a-row>
      </a-form>
    </div>

    <div class="operator">
      <a-button @click="addRecord">新增</a-button>
      <a-button @click="batchDelete" >删除</a-button>
    </div>
    <a-table ref="TableInfo" 
      :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" 
      :pagination="pagination" 
      @change="handleTableChange" 
      bordered 
      :loading="loading" 
      :columns="columns" 
      :rowKey="(record) => record.id"
      :data-source="dataSource">
      <template slot="userlist" slot-scope="list, record">
        <template v-for="(data, index) of list">
          <a-tag
            style="margin: 0 5px 5px"
            :key="index"
            v-if="record.showAll || index < 6"
          >
            {{ data.username }}
          </a-tag>
        </template>

        <span
          style="white-space: nowrap; color: #3db6fc; cursor: pointer;"
          v-if="list.length > 6"
          @click="record.showAll = !record.showAll"
        >
          {{ record.showAll ? "折叠▲" : "展开▼" }}
        </span>
      </template>
      <template slot="link" slot-scope="text"
    >
      <a :href="text">{{text}}</a>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editRecord(record)" title="修改"></a-icon>
      </template>
    </a-table>
    <InvestigationEdit ref="communityEdit" @close="handleEditClose" @success="handleEditSuccess" :editVisiable="editVisiable" />
    <InvestigationAdd ref="communityAdd" @close="handleAddClose" @success="handleAddSuccess" :addVisiable="addVisiable" />
  </a-card>
</template>

<script>
import InvestigationAdd from "./InvestigationAdd"
import InvestigationEdit from "./InvestigationEdit"

const columns = [
  {
    title: "调研对象",
    dataIndex: "userlist",
    scopedSlots: { customRender: "userlist" },
  },
  {
    title: "问卷名称",
    dataIndex: "title",
    width: 200
    // ellipsis: true
  },
  {
    title: "描述",
    dataIndex: "description",
    // ellipsis: true
  },
  {
    title: "开始日期",
    dataIndex: "beginDate",
    width: 150
    // ellipsis: true
  },
  {
    title: "结束日期",
    dataIndex: "endDate",
    width: 150
    // ellipsis: true
  },
  {
    title: "问卷链接",
    dataIndex: "link",
    scopedSlots: { customRender: 'link' },
    width: 400
    // ellipsis: true
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    fixed: "right",
  }
];


export default {
  components: {InvestigationAdd, InvestigationEdit},
  data() {
    return {
      columns,
      dataSource: [],
      selectedRowKeys: [],
      queryParams: {
        title: undefined
      },
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      editVisiable: false,
      addVisiable: false
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    editRecord(record) {
      this.editVisiable = true;
      this.$refs.communityEdit.setFormFields(record);
    },
    handleEditClose() {
      this.editVisiable = false;
    },
    handleEditSuccess() {
      this.editVisiable = false;
      this.$message.success("修改问卷相关成功！");
      this.fetch();
    },
    addRecord() {
      this.addVisiable = true;
    },
    handleAddClose() {
      this.addVisiable = false;
    },
    handleAddSuccess() {
      this.fetch();
      this.addVisiable = false;
      this.$message.success("添加问卷成功！");
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    onSelectChange (selectedRowKeys) {
      console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    search () {
      this.fetch({
        ...this.queryParams
      })
    },
    fetch(params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get('questionnaire-investigation', {
        ...params
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.pagination = pagination;
        this.loading = false;
        
        let datasource = data.rows;
        let set = new Set();
        for (let item of datasource) {
          for (const id of item.userIdValueList) {
            set.add(id);
          }
        }
        let ids = [...set].join(",");

        this.$get(`user/list/simple/${ids}`).then((resp) => {
          let arr = resp.data.data;

          for (let item of datasource) {
            item.userlist = []
            item.showAll = false;
            for (const id of item.userIdValueList) {
              let one = arr.find((i) => i.userId == id);
              if (one) {
                item.userlist.push(one);
              }
            }
            item.creator = arr.find((i) => i.userId == item.createUserId);
          }

          this.dataSource = datasource;
        });
      })
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`questionnaire-investigation/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("问卷删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    },
		reset() {
		  // 取消选中
		  this.selectedRowKeys = [];
		  // 重置分页
		  this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
		  if (this.paginationInfo) {
		    this.paginationInfo.current = this.pagination.defaultCurrent;
		    this.paginationInfo.pageSize = this.pagination.defaultPageSize;
		  }
		  // 重置列过滤器规则
		  this.filteredInfo = null;
		  // 重置列排序规则
		  this.sortedInfo = null;
		  // 重置查询参数
		  this.queryParams = {
		    title: undefined
		  },
		  this.fetch();
		},
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>